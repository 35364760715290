import getAgent, { getAccurateAgent } from '@egjs/agent';
import { type AgentInfo } from '@egjs/agent/declaration/types';

export interface IAgentHelper {
  getSyncAgent(): AgentInfo;
  getAsyncAgent(): AgentInfo | undefined;
}

class AgentHelper implements IAgentHelper {
  private syncAgent: AgentInfo;
  private asyncAgent?: AgentInfo;
  constructor() {
    this.syncAgent = getAgent(navigator.userAgent);
    getAccurateAgent((agent) => {
      this.asyncAgent = agent;
    });
  }
  getSyncAgent(): AgentInfo {
    return this.syncAgent;
  }
  getAsyncAgent(): AgentInfo | undefined {
    return this.asyncAgent;
  }
}

const defaultAgentHelper = new AgentHelper();

export const isMobile = (
  helper: IAgentHelper = defaultAgentHelper
): boolean => {
  // use screen width for tablet detection
  const nonTablet = window.screen.width < 768;
  return helper.getSyncAgent().isMobile && nonTablet;
};

export const shouldShowUpgradeChromeDialog = (
  helper: IAgentHelper = defaultAgentHelper
): boolean => {
  const version = helper.getSyncAgent().browser.version;
  const [, ...vnums] = /(\d+)\.(\d+)\.(\d+)\.(\d+)/.exec(version) ?? [];
  const [major, minor, build, patch] = vnums.map((n) => parseInt(n, 10));

  // Chrome 92.0.4515.129 raised the limit for MediaPlayers to 1000.
  // https://bugs.chromium.org/p/chromium/issues/detail?id=1232649
  return (
    helper.getSyncAgent().browser.chromium &&
    major === 92 &&
    minor === 0 &&
    (build < 4515 || patch < 129)
  );
};

export type SimplifedAgentInfo = {
  browser: {
    isWebkit: boolean;
    isChromium: boolean;
    isFirefox: boolean;
  };
  os: {
    isMac: boolean;
    isWin: boolean;
    isAndroid: boolean;
    isIOS: boolean;
    name: string;
  };
};

export function getAgentInfo(
  helper: IAgentHelper = defaultAgentHelper
): SimplifedAgentInfo {
  const agent = helper.getSyncAgent();
  return {
    browser: {
      isWebkit: !!agent.browser?.webkit,
      isChromium: !!agent.browser?.chromium,
      isFirefox: agent.browser?.name.toLowerCase() === 'firefox',
    },
    os: {
      isMac: agent?.os.name.toLowerCase() === 'mac',
      isWin: agent?.os.name.toLowerCase() === 'window',
      isAndroid: agent?.os.name.toLowerCase() === 'android',
      isIOS: agent?.os.name.toLowerCase() === 'ios',
      name: agent.os.name.toLowerCase(),
    },
  };
}
